<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 请选择组织 -->
					<el-form-item label="选择组织" prop="choose">
						<div class="aaaa">
							<el-input v-model="form.choose" readonly placeholder="请选择所属工会"
								@click="visible = true"></el-input>
						</div>
					</el-form-item>
					<!-- 填写组织名称 -->
					<el-form-item label="组织名称" prop="name">
						<div class="aaaa">
							<el-input v-model="form.name" placeholder="请填写组织名称"></el-input>
						</div>
					</el-form-item>
					<!-- 组织类型 -->
					<el-form-item label="组织类型" prop="genre">
						<el-select v-model="form.genre" placeholder="组织类型">
							<el-option label="工会" value="工会"></el-option>
							<el-option label="部门" value="部门"></el-option>
						</el-select>
					</el-form-item>
					<!-- 提交按钮 -->
					<div class="submit">
						<el-form-item>
							<el-button type="primary" :loading="loa" @click="onSubmit">提交</el-button>
						</el-form-item>
					</div>
					<div>
						<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']"
							@yes="yes">
							<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
						</s3-layer>
					</div>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onMounted,
		onActivated
	} from "vue";
	// import { useRoute } from 'vue-router';
	import {
		Obtain,
		Zzlist,
		Redact
	} from '../../utils/api'
	import qs from 'qs'
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		ElMessage
	} from "element-plus";
	import {
		useStore
	} from "vuex";
	export default {
		name: "zzedit",
		setup() {
			const route = useRoute();
			// 加载框
			const loa = ref(false)
			let loading = ref(true)
			// 获取所属组织接口
			const messge = () => {
				Zzlist().then((res) => {
					console.log(res)
					texture.value = res.data.data.wholeUnionList
					const arr3 = texture.value.filter(function(num) {
						return num.unionCode == hqcode.value
					})
					if (arr3.length == 0) {
						texture.value.forEach((item) => {
							const arr4 = item.unionDtoList.filter(function(num) {
								return num.unionCode == hqcode.value
							})
							if (arr4.length == 0) {
								item.unionDtoList.forEach((items) => {
									const arr5 = items.unionDtoList.filter(function(nums) {
										return nums.unionCode == hqcode.value
									})
									console.log(arr5)
									arr5.forEach((item) => {
										form.choose = arr3.unionName = item.unionName
									})
								})
							} else {
								arr4.forEach((item) => {
									form.choose = arr3.unionName = item.unionName
								})
							}
						})
					} else {
						arr3.forEach((item) => {
							form.choose = arr3.unionName = item.unionName
						})
					}
					loading.value = false
				})
			}
			// 所属工会选择
			const yes = () => {
				form.choose = options.value
				visible.value = false;
			};
			// 获取工会code
			let gaincode = ref('')
			// 树形选择
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.choose = options.value
				gaincode.value = node.unionCode
			}
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			let texture = ref([])
			const visible = ref(false);
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				if (translate.value == 1) {
					loading.value = true
					acquire()
				}
			})
			onMounted(() => {
				acquire()
			})
			// 获取组织code
			let hqcode = ref('')
			// 组织编辑接口
			const acquire = () => {
				let data = {
					unionId: sessionStorage.getItem('userId')
				}
				Obtain(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						messge()
						hqcode.value = res.data.data.unionInfo.parentUnionCode
						form.name = res.data.data.unionInfo.unionName
						if (res.data.data.unionInfo.unionType == 1) {
							form.genre = '工会'
						} else if (res.data.data.unionInfo.unionType == 2) {
							form.genre = '部门'
						}
					}
				})
			}
			// 表单内容
			const ruleFormRef = ref(null)
			const form = reactive({
				name: "",
				genre: "",
				choose: ""
			})
			// 表单提交
			const router = useRouter();
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			const onSubmit = () => {
				// 表单校验
				loa.value = true
				console.log(form)
				// 组织类型
				let types = ref(null)
				if (form.genre == '工会') {
					types.value = 1
				} else if (form.genre == '部门') {
					types.value = 2
				}
				// 选择组织
				let ele = ref('')
				ele.value = gaincode.value
				if (ele.value == '') {
					ele.value = hqcode.value
				}
				ruleFormRef.value.validate((valid) => {
					if (valid == true) {
						let data = {
							"unionID": sessionStorage.getItem('userId'),
							"unionCode": ele.value,
							"unionName": form.name,
							"unionType": types.value
						}
						Redact(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: "提交成功",
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'branch',
										params: {
											userId: 1
										}
									});
									acquire()
									loa.value = false
								}, 500)
							}
						})
					} else if (valid == false) {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						loa.value = false
					}
				})
			}
			// 验证表单提示
			const rules = {
				choose: [{
					required: true,
					message: "请选择组织",
					trigger: "change"
				}, ],
				name: [{
					required: true,
					message: "请填写组织名称",
					trigger: "blur"
				}, ],
				genre: [{
					required: true,
					message: "请选择组织权限",
					trigger: "blur"
				}, ],
			}
			return {
				// 加载中
				loa,
				loading,
				// 树形
				visible,
				texture,
				defaultProps,
				dendrogram,
				yes,
				// 表单内容
				ruleFormRef,
				form,
				// 表单提交按钮
				onSubmit,
				rules
			}
		},
	}
</script>>

<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 125px !important;
		font-weight: bold;
	}

	::v-deep .submit {
		margin-left: 125px;
	}
</style>